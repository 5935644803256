@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.special {
  font-family: 'Pacifico', cursive;
}

.nicer {
  font-family: 'Abril Fatface', cursive;
}

.line {
  display: block;
  width: 50px;
  height: 5px;
  margin: 10px auto;
  background-color: #DC2C2C;
}

.sttelemedia {
  font-family: 'Lato', sans-serif;
}

.screen {
  font-family: 'Abril Fatface', cursive;
  font-family: 'Lato', sans-serif;
  font-family: 'Pacifico', cursive;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
}
