.hero {
    width: 100%,;
    height: 100vh;
}

video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



